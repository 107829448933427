// @flow
import * as React from 'react';
import { render } from './utils/render';
import ErrorPage from './error-page';
import Container from './container';

const id = 'root-422';

function UnprocessableEntry(): React.Node {
  return (
    <Container>
      <ErrorPage code="422" />
    </Container>
  );
}

render(id, UnprocessableEntry);

export default { Page: UnprocessableEntry, id };
