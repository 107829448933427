// @flow
import * as React from 'react';

import { getLocale } from '../i18n';
import settings from '../settings';
import Link from '../link';

import t from './locale';
import cs from './styles.pcss';

const now = new Date();

export default function Footer(): React.Node {
  return (
    <ul className={cs.footer}>
      <li className={cs.item}>
        <Link
          className={cs.link}
          href={settings.helpCenter(getLocale())}
          area="footer"
        >
          {t('helpCenter')}
        </Link>
      </li>
      <li className={cs.item}>
        <Link
          className={cs.link}
          href={settings.faq(getLocale())}
          area="footer"
        >
          {t('faq')}
        </Link>
      </li>
      <li className={cs.item}>
        <Link className={cs.link} href="/articles/terms" area="footer">
          {t('terms')}
        </Link>
      </li>
      <li className={cs.item}>{t('copyright', { year: now.getFullYear() })}</li>
      <li className={cs.item}>{t('message')}</li>
    </ul>
  );
}
