// @flow
import * as React from 'react';

import Button from '../button';
import settings from '../settings';

import cs from './styles.pcss';
import t from './locale';

export default function Connect(): React.Node {
  return (
    <div className={cs.connect}>
      <Button
        mode="filled"
        color="white"
        href={settings.signIn}
        area="connect"
        withArrow
      >
        {t('buttonText')}
      </Button>
    </div>
  );
}
