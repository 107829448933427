// @flow
import * as React from 'react';

import Button from '../../button';
import settings from '../../settings';

import cs from './styles.pcss';
import t from './locale';

export default function SignInButton(): React.Node {
  return (
    <div className={cs.signInButton}>
      <Button
        mode="secondary"
        size="small"
        href={settings.signIn}
        area="header"
      >
        <span className={cs.mobile}>{t('buttonText')}</span>
        <span className={cs.desktop}>{t('buttonTextDesktop')}</span>
      </Button>
    </div>
  );
}
