// @flow
import * as React from 'react';

import './styles.pcss';

type Props = {
  children: React.Node,
};

export default function Container({ children }: Props): React.Node {
  return <div>{children}</div>;
}
