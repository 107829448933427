// @flow
import * as React from 'react';
import { render } from './utils/render';
import ErrorPage from './error-page';
import Container from './container';

const id = 'root-500';

function ServerError(): React.Node {
  return (
    <Container>
      <ErrorPage code="500" />
    </Container>
  );
}

render(id, ServerError);

export default { Page: ServerError, id };
