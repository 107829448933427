// @flow
import * as React from 'react';
import { render } from './utils/render';
import ErrorPage from './error-page';
import Container from './container';

const id = 'root-404';

function NotFound(): React.Node {
  return (
    <Container>
      <ErrorPage code="404" />
    </Container>
  );
}

render(id, NotFound);

export default { Page: NotFound, id };
