// @flow
import * as React from 'react';
import Container from './container';
import MainLandBlock from './main-land-block';
import FreeButNotFree from './free-but-not-free';
import Features from './features';
import Audience from './audience';
import HowTo from './howto';
import Questions from './questions';
import Connect from './connect';
import Footer from './footer';
// import Feedback from './feedback';
import Header from './header';
import { render } from './utils/render';

// should be unique for every page
const id = 'root';
// remove Reviews from the page
function App(): React.Node {
  return (
    <Container>
      <Header />
      <MainLandBlock />
      <Features />
      <Audience />
      <HowTo />
      <Questions />
      <Connect />
      <FreeButNotFree />
      <Footer />
      {/* <Feedback /> */}
    </Container>
  );
}

render(id, App);

export default { Page: App, id };
