// @flow
import * as React from 'react';

import Logo from '../logo.svg';
import SignInButton from './sign-in-button';
import LangSwitcher from './lang-switcher';

import cs from './styles.pcss';

type Props = {
  url?: string,
};

export default function Header({ url }: Props): React.Node {
  return (
    <div className={cs.wrapper}>
      <div className={cs.header}>
        <Logo className={cs.logo} />
        <div className={cs.controls}>
          <LangSwitcher url={url} />
          <SignInButton />
        </div>
      </div>
    </div>
  );
}
