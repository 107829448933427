// @flow

import * as localeLoader from 'locale-loader';

import { logError } from './sentry';

// Set default locale

let currentLocale = 'en';

const availableLocales = [
  'en',
  'zh',
  'ja',
  'ko',
  'ru',
  'es',
  'pt',
  'fr',
  'it',
  'de',
  'pl',
];

// Set fallback locale when there is no translation for target language
localeLoader.setFallbackLocale('en');

// Log missing translations to sentry (if fallback locale also fails)
localeLoader.onTranslationNotFound((locale, key) => {
  logError(`Missing translation for ${locale}:${key}`);
  return `${locale}:${key}`;
});

localeLoader.onTranslationFormatError((error, locale, key) => {
  logError(`
    Can not parse translation for ${locale}:${key}
    Error: ${error}
  `);
  return `${locale}:${key}`;
});

export function setLocale(locale: string) {
  if (availableLocales.indexOf(locale) !== -1) {
    currentLocale = locale;
    localeLoader.setLocale(locale);
  }
}

export function getLocale(): string {
  return currentLocale;
}
