// @flow
import * as React from 'react';
import cx from 'classnames';

import step1 from './step1.png';
import step2 from './step2.png';
import step3 from './step3.png';
import stepGif from './steps.gif';
import cs from './styles.pcss';
import t from './locale';

export default function HowTo(): React.Node {
  const [isShownStep1, setIsShownStep1] = React.useState(false);
  const [isShownStep2, setIsShownStep2] = React.useState(false);
  const [isShownStep3, setIsShownStep3] = React.useState(false);

  const showStep = (step) => {
    switch (step) {
      case 1:
        setIsShownStep1(true);
        setIsShownStep2(false);
        setIsShownStep3(false);
        break;
      case 2:
        setIsShownStep2(true);
        setIsShownStep1(false);
        setIsShownStep3(false);
        break;
      case 3:
        setIsShownStep3(true);
        setIsShownStep2(false);
        setIsShownStep1(false);
        break;
      default:
        setIsShownStep1(false);
        setIsShownStep2(false);
        setIsShownStep3(false);
    }
  };

  const hideStep = () => {
    setIsShownStep1(false);
    setIsShownStep2(false);
    setIsShownStep3(false);
  };

  const renderListItem = (index) => (
    <p
      // eslint-disable-next-line
      key={index}
      className={cs.listElement}
      onMouseEnter={() => showStep(index + 1)}
      onMouseLeave={hideStep}
    >
      <span className={cs.listElementCount}>{index + 1}</span>
      <span className={cs.listElementText}>{t(`steps.${index}`)}</span>
    </p>
  );

  return (
    <div className={cs.howTo}>
      <h2 className={cs.title}>{t('title')}</h2>

      <div className={cs.stepsContainer}>
        <div className={cs.stepsList}>
          {[...Array(3)].map((_, index) => renderListItem(index))}
        </div>

        <div className={cs.placeholderContainer}>
          <img
            className={cs.placeholderImage}
            height="auto"
            src={step1}
            alt="step 1"
            loading="lazy"
          />
          <div className={cs.gifContainer}>
            <img
              className={cx(cs.stepImage, {
                [cs.stepImageVisible]: isShownStep1,
              })}
              height="auto"
              src={step1}
              alt="step 1"
              loading="lazy"
            />
            <img
              className={cx(cs.stepImage, {
                [cs.stepImageVisible]: isShownStep2,
              })}
              height="auto"
              src={step2}
              alt="step 2"
              loading="lazy"
            />

            <img
              className={cx(cs.stepImage, {
                [cs.stepImageVisible]: isShownStep3,
              })}
              height="auto"
              src={step3}
              alt="step 3"
              loading="lazy"
            />

            <img
              className={cs.stepsGif}
              width="100%"
              height="auto"
              src={stepGif}
              alt="steps gif"
              loading="lazy"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
