// @flow
import * as React from 'react';
import cx from 'classnames';

import LazyBackgroundImage from '../lazy-background-image';
import Button from '../button';
import Link from '../link';
import { getLocale } from '../i18n';
import settings from '../settings';

import cs from './styles.pcss';
import t from './locale';
import Vk from './vk.svg';
import Fb from './fb.svg';
import Hc from './hc.svg';

const contacts = {
  vk: {
    icon: Vk,
    locales: {
      ru: 'https://vk.com/ebayexportrussia',
    },
  },
  fb: {
    icon: Fb,
    locales: {
      lt: 'https://www.facebook.com/ebaylatvija',
      lv: 'https://www.facebook.com/eBayLietuva',
      ru: 'https://www.facebook.com/eBayExportRussia',
    },
  },
};

function getContactButton(code: string, locale: string) {
  const data = contacts[code];
  if (!code || !data) return null;
  if (data.locales && !data.locales[locale]) {
    return null;
  }

  const href = data.href || data.locales[locale];

  const Icon = data.icon;
  return (
    <Link
      href={href}
      alt={code}
      className={cx(cs.contact, cs.link)}
      area="questions"
    >
      <Icon className={cx(cs.iconLink, cs[code])} />
    </Link>
  );
}

export default function Questions(): React.Node {
  const locale = getLocale();
  const lowerCaseLoc = locale.toLowerCase();

  return (
    <div className={cs.bannerGroup}>
      <LazyBackgroundImage className={cs.questions}>
        <div className={cs.wrapper}>
          <div className={cs.content}>
            <h2 className={cs.title}>{t('title')}</h2>
            <div className={cs.description}>{t('description')}</div>
          </div>
          <div className={cs.links}>
            <Button
              href={settings.faq(locale)}
              blank
              area="questions"
              className={cx(cs.link, cs.faqButton)}
              withArrow
            >
              {t('faqText')}
            </Button>
            <Button
              href={settings.helpCenter(locale)}
              blank
              area="questions"
              className={cx(cs.link, cs.helpCenterText)}
              withArrow
            >
              {t('helpCenterText')}
            </Button>
            <Link
              href={settings.helpCenter(locale)}
              alt="Help Center"
              className={cx(cs.contact, cs.link, cs.helpCenterIcon)}
              area="questions"
            >
              <Hc className={cx(cs.iconLink, 'hc')} />
            </Link>
            {getContactButton('fb', lowerCaseLoc)}
            {getContactButton('vk', lowerCaseLoc)}
          </div>
        </div>
      </LazyBackgroundImage>
    </div>
  );
}
