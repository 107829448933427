// @flow
import * as React from 'react';

import t from '../locale';
import cs from './styles.pcss';
import images from '../images/images';

export type ScreenType = 'desktop' | 'tablet' | 'mobile';
type Props = {
  screen: ScreenType,
};

export default function VisualDemo({ screen }: Props): React.Node {
  const picture = (picName: string, imagesSrc: {}) => {
    const picSrc = {
      png: imagesSrc[picName],
      png2x: imagesSrc[`${picName}2x`],
      webP: imagesSrc[`${picName}WebP`],
      webP2x: imagesSrc[`${picName}WebP2x`],
    };

    return (
      <picture className={cs[picName]}>
        <source
          srcSet={`${picSrc.webP} 1x, ${picSrc.webP2x} 2x`}
          type="image/webp"
        />
        <img
          className={cs[picName]}
          width="100%"
          height="auto"
          alt={picName}
          src={`${picSrc.png}`}
          srcSet={`${picSrc.png} 1x, ${picSrc.png2x} 2x`}
          loading="lazy"
        />
      </picture>
    );
  };

  const mobileBlock = (): React.Node => (
    <div className={cs.container}>
      <h3 className={cs.header2}>{t('descriptionPart1')}</h3>
      {picture('ebayScreen', images)}
      <h3 className={cs.header2}>{t('descriptionPart2')}</h3>
      {picture('stockV', images)}
    </div>
  );

  const tabletBlock = (): React.Node => (
    <div className={cs.container}>
      <h3 className={cs.header2}>{t('descriptionPart1')}</h3>
      <div className={cs.tabletImagesContainer}>
        <div className={cs.ebayScreenContainer}>
          {picture('ebayScreen', images)}
        </div>
        <div className={cs.importSuccessContainer}>
          {picture('importSuccess', images)}
        </div>
      </div>
      <h3 className={cs.header2}>{t('descriptionPart2')}</h3>
      {picture('stockH', images)}
    </div>
  );

  const desktopBlock = (): React.Node => (
    <div className={cs.container}>
      {picture('ebayScreen', images)}
      <div className={cs.importGifContainer}>
        {picture('importSuccess', images)}
        <img
          className={cs.importGif}
          width="100%"
          height="auto"
          src={images.importGif}
          alt="import gif"
          loading="lazy"
        />
      </div>
      <div className={cs.listingGifContainer}>
        {picture('stockH', images)}
        <img
          className={cs.listingGif}
          width="100%"
          height="auto"
          src={images.listingGif}
          alt="listing gif"
          loading="lazy"
        />
      </div>
    </div>
  );

  switch (screen) {
    case 'mobile':
      return mobileBlock();
    case 'tablet':
      return tabletBlock();
    default:
      return desktopBlock();
  }
}
