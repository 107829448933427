// @flow
import * as React from 'react';

import cs from './styles.pcss';
import t from './locale';
import Buyers from './buyers.svg';
import Listings from './listings.svg';
import Logistics from './logistics.svg';
import Worldwide from './worldwide.svg';

const icons = [
  <Listings className={cs.icon} />,
  <Worldwide className={cs.icon} />,
  <Logistics className={cs.icon} />,
  <Buyers className={cs.icon} />,
];

export default function Features(): React.Node {
  return (
    <div className={cs.features}>
      <h2 className={cs.title}>{t('title')}</h2>
      <div className={cs.featuresContainer}>
        {[...Array(4)].map((_, index) => (
          // eslint-disable-next-line
          <div className={cs.feature} key={index}>
            {icons[index]}
            <h3 className={cs.featureTitle}>{t(`features.${index}.title`)}</h3>
            <div className={cs.description}>
              {t(`features.${index}.description`)}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
