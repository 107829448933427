// @flow
import * as React from 'react';

import useMediaQuery from '../hooks/use-media-query';
import Button from '../button';
import VisualDemo from './visual-demo';
import settings from '../settings';
import type { ScreenType } from './visual-demo';
import { getLocale } from '../i18n';

import t from './locale';
import cs from './styles.pcss';
import Globe from './globe.svg';
import EbayLogo from './ebay-logo.svg';

export default function MainLandBlock(): React.Node {
  const [screenState, setScreenState] = React.useState('desktop');
  const lessThanMedium = useMediaQuery(`(max-width: 767px)`);
  const moreThanMedium = useMediaQuery(`(min-width: 768px)`);
  const lessThanVeryLarge = useMediaQuery(`(max-width: 1279px)`);
  const locale = getLocale();

  function defineScreen(
    lessThanMediumPoint: boolean,
    moreThanMediumPoint: boolean,
    lessThanVeryLargePoint: boolean
  ): ScreenType {
    if (lessThanMediumPoint) {
      return 'mobile';
    }
    if (moreThanMediumPoint && lessThanVeryLargePoint) {
      return 'tablet';
    }
    return 'desktop';
  }

  React.useEffect(() => {
    setScreenState(
      defineScreen(lessThanMedium, moreThanMedium, lessThanVeryLarge)
    );
  }, [lessThanMedium, moreThanMedium, lessThanVeryLarge]);

  return (
    <div className={cs.container}>
      <h1 className={cs.mainTitle}>{t('title')}</h1>
      {locale === 'zh' && <h1 className={cs.mainTitle}>{t('subTitle')}</h1>}
      <p className={cs.description}>{t('description')}</p>
      <Globe className={cs.globe} />
      <div className={cs.authButtonContainer}>
        <Button
          href={settings.signIn}
          area="mainLandingBlock"
          mode="filled"
          color="brightBlue"
          withArrow
        >
          {t('buttonTitle')}
        </Button>
      </div>
      {screenState === 'desktop' && <VisualDemo screen={screenState} />}
      <div className={cs.ebayLogoContainer}>
        {locale === 'de' ? (
          <>
            <span className={cs.logoText}>{t('logoText.part1')}</span>
            <span className={cs.logoText}>{t('logoText.part2')}</span>
            <EbayLogo className={cs.ebayLogo} />
          </>
        ) : (
          <>
            <span className={cs.logoText}>{t('logoText.part1')}</span>
            <EbayLogo className={cs.ebayLogo} />
            <span className={cs.logoText}>{t('logoText.part2')}</span>
          </>
        )}
      </div>
      {screenState !== 'desktop' && <VisualDemo screen={screenState} />}
    </div>
  );
}
