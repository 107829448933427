import { init, captureException, withScope } from '@sentry/browser';
import { path } from 'ramda';

const shouldUseSentry = process.env.SOURCE_VERSION;
let prevMessage = null;
let counter = 0;

if (shouldUseSentry) {
  let env = process.env.CIRCLE_BRANCH;
  if (typeof env === 'string' && env.length) {
    // sentry can't process env with '/'
    env = env.replace('/', '-');
  } else {
    env = 'mystical';
  }

  // init sentry
  init({
    dsn: process.env.SENTRY_DSN,
    release: process.env.SOURCE_VERSION,
    environment: env,
    ignoreErrors: [/ActionController::InvalidAuthenticityToken/],
    debug: process.env.NODE_ENV === 'development',
    beforeSend(event, hint) {
      const message = path(['originalException', 'message'], hint);

      // don't send same event again
      if (prevMessage && prevMessage === message) {
        // but count it
        counter += 1;

        // send every 10, 50, 100 event
        if (counter === 10 || counter === 50 || counter === 100) {
          if (!event.extra) {
            // eslint-disable-next-line no-param-reassign
            event.extra = {};
          }
          // eslint-disable-next-line no-param-reassign
          event.extra.count = counter;
          return event;
        }

        return null;
      }

      prevMessage = message;
      return event;
    },
  });
}

export function logError(error, extra) {
  if (shouldUseSentry) {
    if (extra) {
      withScope((scope) => {
        scope.setExtra('debugInfo', extra);
        captureException(new Error(error));
      });
    } else {
      captureException(new Error(error));
    }
  } else {
    console.error(error, extra); // eslint-disable-line
  }
}
