// @flow
import * as React from 'react';

import QuestionHollow from './question.svg';
import Logo from '../logo.svg';

import t from './locale';
import cs from './styles.pcss';

type Props = {
  code: '404' | '422' | '500' | 'ruDisabled',
};

export default function ErrorPage({ code }: Props): React.Node {
  const tCode = React.useCallback(
    (text: string) => t(`${code}.${text}`),
    [code]
  );

  const codeButtonClick = React.useCallback(() => {
    if (code === '404') {
      history.back();
    }

    if (code === '500') {
      location.reload();
    }

    if (code === '422') {
      window.location.href = '/';
    }

    if (code === 'ruDisabled') {
      window.location.href = '/login';
    }
  }, [code]);

  return (
    <div className={cs.errorPage}>
      <Logo className={cs.logo} />
      <div className={cs.info}>
        <div className={cs.title}>{tCode('title')}</div>
        <div className={cs.clarification}>
          {tCode('clarification.first')}
          <br />
          {tCode('clarification.second')}
        </div>
        <button type="button" className={cs.back} onClick={codeButtonClick}>
          <div className={cs.content}>{tCode('buttonText')}</div>
        </button>
      </div>
      <a className={cs.support} href="mailto:support@ebaymag.com">
        <QuestionHollow />
        <div className={cs.supportContent}>{tCode('support')}</div>
      </a>
    </div>
  );
}
