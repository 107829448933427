// @flow
import * as React from 'react';

import Link from '../link';
import { useSpecialLanguage } from '../hooks/use-special-language';
import settings from '../settings';
import { getLocale } from '../i18n';

import cs from './styles.pcss';
import t from './locale';

export default function FreeButNotFree(): React.Node {
  const locale = getLocale();
  const defaultLanguage = useSpecialLanguage([
    'en',
    'ru',
    'fr',
    'it',
    'de',
    'pl',
    'ja',
    'ko',
  ]);

  return defaultLanguage ? (
    <div className={cs.freeButNotFree}>
      <div className={cs.wrapper}>
        <div className={cs.block}>
          {t(`blocks.${0}`)}
          <span> </span>
          <Link
            href={
              settings.moreAboutPaindInsertions[locale] ||
              settings.moreAboutPaindInsertions.en
            }
            area="paid-insertion"
            className={cs.learnMore}
            alt="Learn more about insertion fees"
          >
            {t('learnMore')}
          </Link>
        </div>
        <div className={cs.block}>{t(`blocks.${1}`)}</div>
        <div className={cs.block}>{t(`blocks.${2}`)}</div>
      </div>
    </div>
  ) : null;
}
