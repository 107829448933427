// @flow
import * as React from 'react';

import Connect from '../connect';
import Footer from '../footer';
import { render } from '../utils/render';

// should be unique for every page
const id = 'terms-footer';

function TermsFooter(): React.Node {
  return (
    <>
      <Connect />
      <Footer />
    </>
  );
}

render(id, TermsFooter);

export default { Page: TermsFooter, id };
