// @flow
import * as React from 'react';

// Check for large screen by default
export default function useMediaQuery(
  mediaQuery: string = 'all and (min-width: 1024px)'
): boolean {
  const [matches, setMatches] = React.useState(false);

  React.useEffect(() => {
    const onUpdateMatch = ({ matches: newMatches }) => {
      setMatches(newMatches);
    };

    const matcher = window.matchMedia(mediaQuery);

    if (matcher.addEventListener) {
      matcher.addEventListener('change', onUpdateMatch);
    } else if (matcher.addListener) {
      matcher.addListener(onUpdateMatch);
    }

    onUpdateMatch(matcher);

    return () => {
      if (matcher.removeEventListener) {
        matcher.removeEventListener('change', onUpdateMatch);
      } else if (matcher.removeListener) {
        matcher.removeListener(onUpdateMatch);
      }
    };
  }, [mediaQuery]);

  return matches;
}
