// @flow
import * as React from 'react';
import cx from 'classnames';

import ANALYTICS from '../analytics';

import cs from './styles.pcss';

type Props = {
  children: React.Node,
  className?: string,
  href?: string,
  area: string,
  mode?: 'normal' | 'dark' | 'white',
  alt?: string,
  noUnderline?: boolean,
};

export default function Link({
  children,
  href,
  className,
  area,
  mode = 'normal',
  alt,
  noUnderline,
}: Props): React.Node {
  const click = React.useCallback(
    () => ANALYTICS.click(href, area),
    [href, area]
  );

  return (
    <a
      className={cx(
        cs.link,
        cs[mode],
        { [cs.noUnderline]: noUnderline },
        className
      )}
      onClick={click}
      href={href}
      aria-label={alt}
      target="_blank"
      rel="noopener noreferrer"
    >
      <span className={cs.content}>{children}</span>
    </a>
  );
}
