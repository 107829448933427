// @flow
import qs from 'qs';

export const useFeature = (
  featureID: string,
  localCheck?: () => boolean
): boolean => {
  let enabled = localCheck ? localCheck() : false;

  if (typeof location !== 'undefined') {
    const params = qs.parse(location.search, { ignoreQueryPrefix: true });
    const value = params[featureID];
    if (value && value !== 'false') {
      enabled = true;
    }
  }

  return enabled;
};
