import ReactDOMServer from 'react-dom/server';
import * as React from 'react';
import App from './app';
import TermsHeader from './terms/header';
import TermsFooter from './terms/footer';
import NotFound from './not-found';
import ServerError from './server-error';
import RuDisabled from './ru-disabled';
import UnprocessableEntry from './unprocessable-entry';
import { setLocale } from './i18n';

const getAssets = (locals) => {
  const js = Object.keys(locals.webpackStats.compilation.assets).find((i) =>
    i.endsWith('js')
  );

  const css = Object.keys(locals.webpackStats.compilation.assets).find((i) =>
    i.endsWith('css')
  );

  const path = `${locals.assetsHost}${locals.publicPath}`;

  return { js, css, path };
};

const cssTemplate = (path, css) => `
  <% content_for :styles do %>
<link rel="stylesheet" href="${path}${css}">
<link rel="preload" href="${path}MarketSans-Bold-WebS.woff2" as="font" type="font/woff2" crossorigin="true">
<link rel="preload" href="${path}MarketSans-Regular-WebS.woff2" as="font" type="font/woff2" crossorigin="true">
  <% end %>
`;

const jsTemplate = (path, js) =>
  `<script src="${path}${js}" crossorigin="anonymous" defer></script>`;

const renderStaticLandingPage = (locals, locale) => {
  setLocale(locale);
  const { js, css, path } = getAssets(locals);

  return `
${cssTemplate(path, css)}
<img width="1" height="1" id="webp-checker" style="display:none" src="data:image/webp;base64,UklGRhoAAABXRUJQVlA4TA0AAAAvAAAAEAcQERGIiP4HAA=="></img>
<script>document.body.classList.add(document.getElementById("webp-checker").height==1?"webp":"no-webp")</script>
<div id="${App.id}">${ReactDOMServer.renderToString(<App.Page />)}</div>
${jsTemplate(path, js)}
`;
};

const renderStaticTermsPage = (locals, locale) => {
  setLocale(locale);
  const { js, css, path } = getAssets(locals);

  return `
${cssTemplate(path, css)}
<div id="${TermsHeader.id}">${ReactDOMServer.renderToString(
    <TermsHeader.Page />
  )}</div>
<div class="ebaymag-terms">${locals.terms[locale]}</div>
<div id="${TermsFooter.id}">${ReactDOMServer.renderToString(
    <TermsFooter.Page />
  )}</div>
${jsTemplate(path, js)}
  `;
};

const renderStaticErrorPage = (locals, locale, id, Page) => {
  setLocale(locale);
  const { js, css, path } = getAssets(locals);

  return `
${cssTemplate(path, css)}
<div id="${id}">${ReactDOMServer.renderToString(<Page />)}</div>
${jsTemplate(path, js)}
  `;
};

const renderPages = (locals) =>
  ['en', 'ru', 'pt', 'es', 'it', 'fr', 'de', 'pl', 'ja', 'ko', 'zh'].reduce(
    (acc, locale) => ({
      ...acc,
      [`/${locale}`]: renderStaticLandingPage(locals, locale),
      [`/terms/${locale}`]: renderStaticTermsPage(locals, locale),
    }),
    {}
  );

const renderErrors = (locals) =>
  ['en', 'ru'].reduce(
    (acc, locale) => ({
      ...acc,
      [`/404/${locale}`]: renderStaticErrorPage(
        locals,
        locale,
        NotFound.id,
        NotFound.Page
      ),
      [`/500/${locale}`]: renderStaticErrorPage(
        locals,
        locale,
        ServerError.id,
        ServerError.Page
      ),
      [`/422/${locale}`]: renderStaticErrorPage(
        locals,
        locale,
        UnprocessableEntry.id,
        UnprocessableEntry.Page
      ),
      [`/215/${locale}`]: renderStaticErrorPage(
        locals,
        locale,
        RuDisabled.id,
        UnprocessableEntry.Page
      ),
    }),
    {}
  );

const renderBatch = (locals) => ({
  ...renderPages(locals),
  ...renderErrors(locals),
});

export default renderBatch;
