// @flow
import { getLocale } from '../i18n';
import { useFeature } from './use-feature';

type Languages = Array<
  | 'bg'
  | 'en'
  | 'es'
  | 'et'
  | 'fr'
  | 'it'
  | 'pt'
  | 'ru'
  | 'de'
  | 'pl'
  | 'ja'
  | 'ko'
  | 'zh'
>;

export const useSpecialLanguage = (languages: Languages): boolean =>
  useFeature('freeOnLandings', () => languages.indexOf(getLocale()) !== -1);
