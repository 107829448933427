// @flow
import * as React from 'react';
import cx from 'classnames';

import cs from './styles.pcss';

type Props = {
  children?: React.Node,
  className: string,
};

export default function LazyBackgroundImage({
  children,
  className,
}: Props): React.Node {
  const ref = React.useRef<HTMLDivElement | null>(null);
  const lazyloadThrottleTimeoutId = React.useRef(null);

  React.useEffect(() => {
    if (ref && ref.current) {
      const element = ref.current;

      if ('IntersectionObserver' in window) {
        const imageObserver = new IntersectionObserver(
          (entries) => {
            entries.forEach((entry) => {
              if (entry.isIntersecting) {
                const image = entry.target;
                image.classList.remove(cs.lazy);
                imageObserver.unobserve(image);
              }
            });
          },
          { rootMargin: '0px 0px 1250px 0px' }
        );
        imageObserver.observe(element);
      } else {
        const lazyload = () => {
          if (lazyloadThrottleTimeoutId && lazyloadThrottleTimeoutId.current) {
            clearTimeout(lazyloadThrottleTimeoutId.current);
            lazyloadThrottleTimeoutId.current = null;
          }

          lazyloadThrottleTimeoutId.current = setTimeout(() => {
            const scrollTop = window.pageYOffset;
            if (element.offsetTop - 1250 < window.innerHeight + scrollTop) {
              element.classList.remove(cs.lazy);
            }
          }, 20);
        };

        document.addEventListener('scroll', lazyload);
        window.addEventListener('resize', lazyload);
        window.addEventListener('orientationChange', lazyload);

        return () => {
          document.removeEventListener('scroll', lazyload);
          window.removeEventListener('resize', lazyload);
          window.removeEventListener('orientationChange', lazyload);
        };
      }
    }
    return () => {};
  }, []);

  return (
    <div className={cx(cs.lazy, className)} ref={ref}>
      {children}
    </div>
  );
}
