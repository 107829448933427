// @flow
import * as React from 'react';

import Button from '../button';
import { getLocale } from '../i18n';
import settings from '../settings';

import t from './locale';
import cs from './styles.pcss';

export default function Audience(): React.Node {
  const locale = getLocale();
  return (
    <div className={cs.container}>
      <h2 className={cs.heading}>{t('heading')}</h2>
      <div className={cs.audience}>
        {locale === 'en' || locale === 'pt' || locale === 'es' ? (
          <h2 className={cs.title}>
            <span className={cs.total}>{t('titleTotal')}</span>
            <span> </span>
            {t('titleStart')}
            <span> </span>
            {t('titleEnd')}
          </h2>
        ) : (
          <h2 className={cs.title}>
            {t('titleStart')}
            <span> </span>
            <span className={cs.total}>{t('titleTotal')}</span>
            <span> </span>
            {t('titleEnd')}
          </h2>
        )}
        <div className={cs.button}>
          <Button
            href={settings.signIn}
            area="audience"
            mode="filled"
            color="brightBlue"
            size="small"
            withArrow
          >
            {t('buttonText')}
          </Button>
        </div>
      </div>
    </div>
  );
}
