// @flow
import * as React from 'react';
import { render } from './utils/render';
import ErrorPage from './error-page';
import Container from './container';

const id = 'root-ru-disabled';

function RuDisabled(): React.Node {
  return (
    <Container>
      <ErrorPage code="ruDisabled" />
    </Container>
  );
}

render(id, RuDisabled);

export default { Page: RuDisabled, id };
