// @flow
type EventPayload = {
  category: string,
  action: string,
  label?: string,
  value?: number,
  ni?: boolean,
};

type GTMEvent = {
  event: 'gaEvent',
  eventdata: {
    category: string,
    action: string,
    label: ?string,
    value: ?number,
    ni: ?boolean,
  },
};

type DataLayer = Array<GTMEvent>;

declare var dataLayer: DataLayer;

const isGTMInitialized = () =>
  typeof global.dataLayer !== 'undefined' && global.dataLayer !== null;

function createEventPayload(eventdata: EventPayload): GTMEvent {
  const eventdataPlaceholder = {
    label: undefined,
    value: undefined,
    ni: undefined,
  };

  // We have to make sure that all eventdata fields are definded
  // to prevent GTM problem from #EBAYMAG-1383
  return {
    event: 'gaEvent',
    eventdata: {
      ...eventdataPlaceholder,
      ...eventdata,
    },
  };
}

function sendEvent(payload: EventPayload): Promise<void> {
  return new Promise((resolve) => {
    const event = createEventPayload(payload);

    if (isGTMInitialized()) {
      const timeoutId = setTimeout(resolve, 500);

      dataLayer.push({
        ...event,
        eventCallback: () => {
          clearTimeout(timeoutId);
          resolve();
        },
      });
    } else {
      resolve();
    }
  });
}

export default {
  languageSwitch: (langKey: string): Promise<void> =>
    sendEvent({ category: `Stock-2`, action: 'language', label: langKey }),
  feedbackShow: (show: boolean): Promise<void> =>
    sendEvent({
      category: 'LandingFeedback',
      action: 'show',
      label: `${show ? 'show' : 'hide'}`,
    }),
  feedbackSend: (success: boolean): Promise<void> =>
    sendEvent({
      category: 'LandingFeedback',
      action: 'send',
      label: `${success ? 'success' : 'error'}`,
    }),
  feedbackSpam: (): Promise<void> =>
    sendEvent({
      category: 'LandingFeedback',
      action: 'spam',
    }),
  click: (href: ?string, area: ?string): Promise<void> =>
    sendEvent({
      category: `LinkClick`,
      action: `LinkClick-${area || 'main'}`,
      label: href || 'default',
    }),
  signInClick: (href: ?string, area: ?string): Promise<void> =>
    sendEvent({
      category: 'SignInClick',
      action: `SignInClick-${area || 'area unknown'}`,
      label: href || 'no href on button',
    }),
};
