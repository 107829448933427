// @flow
import * as React from 'react';

import Container from '../container';
import Header from '../header';
import { render } from '../utils/render';

import './styles.pcss';

// should be unique for every page
const id = 'terms-header';

function TermsHeader(): React.Node {
  return (
    <Container>
      <Header url="/articles/terms" />
    </Container>
  );
}

render(id, TermsHeader);

export default { Page: TermsHeader, id };
