import memoizeFormatConstructor from 'intl-format-cache';
import get from 'lodash-es/get';
import isObject from 'lodash-es/isObject';
import IntlMessageFormat from 'intl-messageformat';
import '@formatjs/intl-pluralrules/polyfill';
import '@formatjs/intl-pluralrules/locale-data/en';
import '@formatjs/intl-pluralrules/locale-data/ru';
import '@formatjs/intl-pluralrules/locale-data/es';
import '@formatjs/intl-pluralrules/locale-data/fr';
import '@formatjs/intl-pluralrules/locale-data/it';
import '@formatjs/intl-pluralrules/locale-data/pt';
import '@formatjs/intl-pluralrules/locale-data/de';
import '@formatjs/intl-pluralrules/locale-data/pl';
import '@formatjs/intl-pluralrules/locale-data/ja';
import '@formatjs/intl-pluralrules/locale-data/ko';
import '@formatjs/intl-pluralrules/locale-data/zh';

const getMessageFormat = memoizeFormatConstructor(IntlMessageFormat);
let currentLocale = 'en';
let fallbackLocale;

let translationNotFoundHandler = (selectedLocale, key) => {
  console.error(`Translation not found for ${selectedLocale}:${key}`); // eslint-disable-line no-console
  return `Translation not found for ${selectedLocale}:${key}`;
};

let translationFormatErrorHandler = (e, selectedLocale, key, translation) => {
  // eslint-disable-next-line no-console
  console.error(`
    Can not parse translation for ${selectedLocale}:${key}
    Translation: ${translation}
    Error:
      ${e}
  `);
  return `Can not parse translation ${selectedLocale}:${key}`;
};

export function setLocale(locale) {
  currentLocale = locale;
}

export function setFallbackLocale(locale) {
  fallbackLocale = locale;
}

export function onTranslationNotFound(f) {
  translationNotFoundHandler = f;
}

export function onTranslationFormatError(f) {
  translationFormatErrorHandler = f;
}

export function getLocale(locales) {
  return function t(key, params) {
    let translation = get(locales[currentLocale], key);

    if (fallbackLocale && typeof translation === 'undefined') {
      translation = get(locales[fallbackLocale], key);
    }

    if (typeof translation === 'undefined') {
      translation = translationNotFoundHandler(currentLocale, key);
    }

    // translation for a nested object
    if (isObject(translation)) {
      return Object.keys(translation).reduce((acc, subKey) => {
        acc[subKey] = t(`${key}.${subKey}`, params);
        return acc;
      }, {});
    }

    try {
      return getMessageFormat(translation, currentLocale).format(params);
    } catch (e) {
      return translationFormatErrorHandler(e, currentLocale, key, translation);
    }
  };
}
