// on client

import * as React from 'react';
import { hydrate } from 'react-dom';
import { setLocale } from '../i18n';

export const render = (id, Component) => {
  if (typeof document !== 'undefined') {
    const root = document.getElementById(id);
    if (root) {
      const docEl = document.documentElement;

      if (docEl) {
        setLocale(docEl.lang);
      }
      hydrate(<Component />, root);
    }
  }
};
