// @flow
import * as React from 'react';
import cx from 'classnames';

import ANALYTICS from '../analytics';
import Loader from '../loader';
import settings from '../settings';

import Arrow from './arrow.svg';
import cs from './styles.pcss';

type Props = {
  children: React.Node,
  className?: string,
  href?: string,
  blank?: boolean,
  area?: string,
  mode?: 'primary' | 'secondary' | 'filled',
  color?: 'blue' | 'white' | 'brightBlue',
  size?: 'small' | 'large',
  onClick?: () => void,
  loading?: boolean,
  disabled?: boolean,
  withArrow?: boolean,
};

export default function Button({
  children,
  mode = 'primary',
  color = 'blue',
  size = 'large',
  className,
  href,
  blank,
  area,
  onClick = () => {},
  loading,
  disabled,
  withArrow,
}: Props): React.Node {
  const classes = cx(
    cs.button,
    cs[color],
    cs[mode],
    cs[size],
    { [cs.loading]: loading },
    className
  );

  const additionalProps = blank
    ? { target: '_blank', rel: 'noopener noreferrer' }
    : {};

  const handleClick = React.useCallback(() => {
    if (disabled) {
      return;
    }
    onClick();
    if (href === settings.signIn) {
      ANALYTICS.signInClick(href, area);
    } else {
      ANALYTICS.click(href, area);
    }
  }, [disabled, href, area, onClick]);

  return href ? (
    <a
      className={classes}
      href={href}
      onClick={handleClick}
      {...additionalProps}
    >
      <div>{children}</div>
      {withArrow && <Arrow className={cs.arrow} />}
    </a>
  ) : (
    <button
      className={classes}
      type="button"
      onClick={onClick}
      disabled={disabled}
    >
      <div className={cs.content}>{children}</div>
      {withArrow && <Arrow className={cs.arrow} />}
      {loading && (
        <div className={cs.loader}>
          <Loader mode="white" size={size === 'small' ? 16 : 24} />
        </div>
      )}
    </button>
  );
}
