// @flow
import * as React from 'react';
import cx from 'classnames';

import cs from './styles.pcss';

type Props = {
  size?: 8 | 16 | 24 | 42 | 48,
  className?: string,
  mode?: 'normal' | 'white',
};

export default function Loader({
  size = 16,
  mode = 'normal',
  className,
}: Props): React.Node {
  return (
    <div className={cx(cs.loader, cs[`size${size}`], cs[mode], className)}>
      <div className={cx(cs.arc, cs.arc1)} />
      <div className={cx(cs.arc, cs.arc2)} />
      <div className={cx(cs.arc, cs.arc3)} />
      <div className={cx(cs.arc, cs.arc4)} />
    </div>
  );
}
