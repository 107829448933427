import importGif from './import-4.1.gif';
import listingGif from './listing-4.2.gif';

import ebayScreen from './ebayScreen.png';
import ebayScreen2x from './ebayScreen@2x.png';
import ebayScreenWebP from './ebayScreen.webp';
import ebayScreenWebP2x from './ebayScreen@2x.webp';

import stockH from './stock-h.png';
import stockH2x from './stock-h@2x.png';
import stockHWebP from './stock-h.webp';
import stockHWebP2x from './stock-h@2x.webp';

import stockV from './stock-v.png';
import stockV2x from './stock-v@2x.png';
import stockVWebP from './stock-v.webp';
import stockVWebP2x from './stock-v@2x.webp';

import importSuccess from './import-success.png';
import importSuccess2x from './import-success@2x.png';
import importSuccessWebP from './import-success.webp';
import importSuccessWebP2x from './import-success@2x.webp';

export default {
  importGif,
  listingGif,
  ebayScreen,
  ebayScreen2x,
  ebayScreenWebP,
  ebayScreenWebP2x,
  stockH,
  stockH2x,
  stockHWebP,
  stockHWebP2x,
  stockV,
  stockV2x,
  stockVWebP,
  stockVWebP2x,
  importSuccess,
  importSuccess2x,
  importSuccessWebP,
  importSuccessWebP2x,
};
